import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CustomImage from '../components/custom-image'
import Grid from '../components/grid'
import LoadMore from '../components/load-more-btn'
import PlayBtn from '../components/play-btn'
import remocoClient from '../remocoClient'
import {
    filterOutItemsWithNoSource,
    get,
    getHeroImageSize,
    getIdentifierFromPath,
    getRelevantEntriesData,
    sendGaPlayClickEvent,
    setGaScreenName,
} from '../utils'

const NUM_EPISODES_TO_ADD = 10

function addEpisodesGrid(id, episodes, setEpisodes, setSeriesInfo, offset, setOffset, showLoadBtn, setShowLoadBtn) {
    if (episodes && episodes.length > 0) {
        let gridFix = ''
        if (episodes.length < 5) {
            // add class to fix card width when there's a small number of cards
            gridFix = 'grid-adjust'
        }
        return (
            <>
                <Grid data={episodes} gridFix={gridFix} />
                {showLoadBtn ? (
                    <LoadMore
                        onClickHandler={async () =>
                            await loadMoreEpisodes(
                                id,
                                episodes,
                                offset,
                                setOffset,
                                setEpisodes,
                                setSeriesInfo,
                                setShowLoadBtn
                            )
                        }
                    />
                ) : (
                    ''
                )}
            </>
        )
    }
    return null
}

async function loadMoreEpisodes(id, episodes, offset, setOffset, setEpisodes, setSeriesInfo, setShowLoadBtn) {
    const newOffset = offset + NUM_EPISODES_TO_ADD

    await getRelevantSeriesPageData(id, newOffset, episodes, setEpisodes, setSeriesInfo, setShowLoadBtn)

    setOffset(newOffset)
}

function getSeriesInfo(seriesData) {
    const { title, synopsis } = seriesData.data
    const image = get(['data', 'resources', 'poster_16-9', 'value'], seriesData)
    const seriesCode = get(['data', 'series_code'], seriesData)

    return { title, description: synopsis, image, seriesCode, seriesData }
}

function filterOutDuplicates(episodes = [], newEpisodes = []) {
    return newEpisodes.filter(ep => {
        const hasFoundDuplicate = !!episodes.find(e => {
            return e.id === ep.id
        })

        return !hasFoundDuplicate
    })
}

async function getSeriesData(id, offset) {
    const expand = 3
    const limit = `1,${NUM_EPISODES_TO_ADD}`
    const seriesData = await remocoClient.getSeriesById(id, expand, limit, offset)

    return seriesData
}

function getEpisodesInfo(seriesData, episodes) {
    const nextEpisodesData = get(['data', 'events', 'list'], seriesData) || []
    const relevantEpisodesData = filterOutItemsWithNoSource(getRelevantEntriesData(nextEpisodesData))
    const newEpisodesData = filterOutDuplicates(episodes, relevantEpisodesData)
    const allEpisodesData = [...episodes, ...newEpisodesData]

    return allEpisodesData
}

function hasMoreEpisodesAvailable(seriesData) {
    return !!get(['data', 'events', 'next'], seriesData)
}

async function getRelevantSeriesPageData(id, offset, episodes, setEpisodes, setSeriesInfo, setShowLoadBtn) {
    const seriesData = await getSeriesData(id, offset)

    if (!seriesData.data) {
        throw new Error('Unable to retrieve data for series with ID:', id)
    }

    setEpisodes(getEpisodesInfo(seriesData, episodes))
    setSeriesInfo(getSeriesInfo(seriesData))
    setShowLoadBtn(hasMoreEpisodesAvailable(seriesData))
}

function getRelevantData(props, episodes, seriesInfo) {
    const items = episodes && episodes.length > 0 ? episodes : props.items
    // merge, fetched series data is preferred
    return {
        ...props,
        ...seriesInfo,
        items,
    }
}

const SeriesDetail = props => {
    const [episodes, setEpisodes] = useState([])
    const [seriesInfo, setSeriesInfo] = useState({})
    const [showLoadBtn, setShowLoadBtn] = useState(true)
    const [offset, setOffset] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    const id = getIdentifierFromPath(props)

    useEffect(() => {
        getRelevantSeriesPageData(id, 0, [], setEpisodes, setSeriesInfo, setShowLoadBtn)
            .then(_ => {
                setIsLoading(false)
            })
            .catch(err => {
                navigate('/404')
            })
    }, [id, props.title, props.items, props.dynamicContentSeriesTitles])

    const { description, id: edifloId, image, title, items = [] } = getRelevantData(props, episodes, seriesInfo)

    useEffect(() => {
        setGaScreenName(`Series Detail - ${title || '(not set)'}`)
    }, [title])

    const firstItem = items[0] || {}

    const playerState = {
        ...firstItem,
        autoplayList: items.slice(1),
        seriesCode: seriesInfo.seriesCode,
    }

    if (isLoading) {
        return null
    }

    return (
        <SeriesContainer>
            <HeroWrapper>
                <InfoWrapper>
                    <Info>
                        <h2>{title}</h2>
                        {description ? <p>{description}</p> : ''}
                        <PlayBtn
                            to={`/player/${items[0].slug}`}
                            state={playerState}
                            text="Play All"
                            onClick={() => sendGaPlayClickEvent(title, edifloId, 'Play All')}
                        />
                    </Info>
                </InfoWrapper>
                <ImageWrapper>
                    <Gradient></Gradient>
                    <CustomImage
                        imgSrc={getHeroImageSize(image, 100)}
                        isHero
                        imgStyle={{
                            width: '75vw',
                            margin: '0',
                        }}
                        alt={title}
                    />
                </ImageWrapper>
            </HeroWrapper>
            {addEpisodesGrid(id, items, setEpisodes, setSeriesInfo, offset, setOffset, showLoadBtn, setShowLoadBtn)}
        </SeriesContainer>
    )
}

const mapStateToProps = state => {
    return {
        dynamicContentSeriesTitles: state.titles,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const ConnectedSeriesDetail = connect(mapStateToProps, mapDispatchToProps)(SeriesDetail)

const HeroWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    @media screen and (max-width: 672px) {
        position: relative;
    }
`

const InfoWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 25vw;
    width: 100%;

    @media screen and (max-width: 672px) {
        position: absolute;
        bottom: 0;
        max-width: unset;
    }
`

const ImageWrapper = styled.div`
    position: relative;
    height: calc(75vw * 0.5625);
    width: 75vw;
    margin-left: auto;

    @media screen and (max-width: 672px) {
        height: calc(100vw * 0.5625);
        width: 100vw;
        margin: 0;

        img {
            width: 100vw !important;
        }
    }
`

const Gradient = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 641px;
    background: linear-gradient(-90deg, rgba(20, 20, 20, 0) 0%, rgb(20, 20, 20) 100%);

    @media screen and (max-width: 672px) {
        background: linear-gradient(-180deg, rgba(20, 20, 20, 0) 0%, rgb(20, 20, 20) 100%);
        height: 64px;
        width: 100%;
        top: unset;
        bottom: 0;
    }
`

const SeriesContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    background-color: #141414;

    .grid {
        padding: 28px var(--discover-page-margin-desktop) 0 var(--discover-page-margin-desktop);

        @media screen and (max-width: 672px) {
            padding: 0 var(--discover-page-margin-mobile) 0 var(--discover-page-margin-mobile);
            margin-top: 90px;
        }
    }
`

const Info = styled.div`
    position: relative;
    margin-right: -400px;
    z-index: 3;
    word-wrap: break-word;
    margin-left: var(--discover-page-margin-desktop);

    a {
        text-decoration: none;
    }

    .play-btn {
        position: initial;
        margin-top: 15px;
    }

    h2 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: white;
        font-size: 36px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 44px;
        margin-bottom: 16px;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: white;
        opacity: 0.7;
        font-size: 16px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 20px;
        height: 60px;
        margin: 0;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 672px) {
        margin-left: var(--discover-page-margin-mobile);
        margin-right: var(--discover-page-margin-mobile);

        .play-btn {
            position: absolute;
            bottom: -52px;
            margin: 0;
            width: 100%;
        }

        h2 {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 20px;
            margin-bottom: 0;
        }
        p {
            display: none;
        }
    }
`

export default ConnectedSeriesDetail
