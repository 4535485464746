import styled from '@emotion/styled'
import React from 'react'

const LoadMoreBtn = ({ onClickHandler }) => {
    return <LoadMore onClick={onClickHandler}>Load More</LoadMore>
}

const LoadMore = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    align-self: center;
    margin: 12px auto 64px auto;
    width: 160px;
    height: 36px;
    border: 1px solid rgb(255, 255, 255);
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    letter-spacing: 2px;
    transition: 0.3s ease-in-out;

    &:hover {
        transition: 0.3s ease-in-out;
        background: white;
        color: #141414;
        cursor: pointer;
    }
`

export default LoadMoreBtn
