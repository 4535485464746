import React from 'react'
import SeriesDetail from '../../components/series-detail'
import Layout from '../../components/layout'

const WatchSeries = ({ location }) => {
    return (
        <Layout>
            <SeriesDetail {...location.state} location={location} />
        </Layout>
    )
}

export default WatchSeries
