import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const PlayBtn = ({ to, state, text }) => {
    return (
        <Link to={to} state={state}>
            <Button className="play-btn">
                <img src="/icon-play.svg" alt="play icon" />
                <span>{text}</span>
            </Button>
        </Link>
    )
}

const Button = styled.button`
    display: flex;
    position: absolute;
    bottom: 48px;
    right: var(--discover-page-margin-desktop);

    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    background: white;
    width: 125px;
    height: 48px;
    border: none;
    font-size: 12px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #141414;
    transition: 0.3s ease-in-out;
    z-index: 2;

    a {
        text-decoration: none;
    }

    span {
        margin-left: 8px;
    }
    > img {
        height: 12px;
        width: 9px;
        margin: 0;
    }

    &:hover {
        background: #559dd4;
        color: white;
        transition: 0.3s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 672px) {
        width: calc(100% - 32px);
        height: 36px;
        bottom: -48px;
        left: 0;
        margin: 0 16px;
    }
`

export default PlayBtn
